import Head from 'next/head'

import { BASE_URL } from '@configs/config'
import { OgShareProps } from './interface'

export function OgShare({ url }: OgShareProps) {
  return (
    <Head>
      <meta property='og:url' content={url} />
      <meta property='og:type' content='article' />
      <meta
        property='og:image'
        content={`${BASE_URL}/images/og/facebook.jpg`}
      />
      <meta
        property='twitter:image'
        content={`${BASE_URL}/images/og/twitter.jpg`}
      />
      <meta name='twitter:card' content='summary_large_image' />
    </Head>
  )
}
