import React from 'react'
import Image from 'next/image'

interface NoDataProps {
  size?: number
}

function NoData({ size = 200 }: NoDataProps) {
  return (
    <div className='flex justify-center'>
      <Image
        src='/images/search-result-empty.webp'
        width={size}
        height={size}
        alt='SearchEmpty'
      />
    </div>
  )
}

export default NoData
