import React, { useMemo } from 'react'
import { useRouter } from 'next/router'

import { BookEnum } from '@interfaces/BookEnum'
import { useCategories } from '@hooks/useCategories'
import { BookOutlineIcon } from '@components/icons/BookOutlineIcon'
import { CartoonIcon } from '@components/icons/CartoonIcon'
import { WithTopRankFilterProps, TopRankFilterProps } from './interface'

const storyTypeOptions = [
  {
    text: 'นิยายรายตอน',
    value: BookEnum.NOVEL,
    icon: <BookOutlineIcon />,
  },
  { text: 'การ์ตูน', value: BookEnum.MANGA, icon: <CartoonIcon /> },
]

export default function withTopRankFilter(
  Component: React.FC<TopRankFilterProps>
) {
  function WithTopRankFilter({
    category,
    storyType,
    ...props
  }: WithTopRankFilterProps) {
    const { query } = useRouter()
    const value = typeof query.id === 'string' ? query.id : ''
    const { novelCategories, mangaCategories } = useCategories()
    const categoryOptions = useMemo(() => {
      const data =
        storyType === BookEnum.MANGA ? mangaCategories : novelCategories
      return [
        { text: 'ทุกหมวดหมู่', value: '' },
        ...data.map(row => ({
          text: row.label,
          value: row.value,
        })),
      ]
    }, [storyType, novelCategories, mangaCategories])

    const componentProps = {
      storyTypeOptions,
      categoryOptions,
      category: category !== undefined ? category : value,
      storyType,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithTopRankFilter
}
