import React from 'react'
import cn from 'classnames'
import Link from 'next/link'
import Image from 'next/image'

import { abbreviateNumber } from '@lib/utils'
import { ArrowUpIcon } from '@components/icons/ArrowUpIcon'
import { EyeIcon } from '@components/icons/EyeIcon'
import { HeartIcon } from '@components/icons/HeartIcon'
import { HeartSolidIcon } from '@components/icons/HeartSolidIcon'
import { Minus2Icon } from '@components/icons/Minus2Icon'
import { PenIcon } from '@components/icons/PenIcon'
import { TopChartIcon } from '@components/icons/TopChartIcon'
import { TopRankCardProps } from './interface'

export function TopRankCard({
  bookDetail,
  className,
  mode = 'swiper',
  slug,
  isLoadingToggleBookLike,
  handleLikeBook,
}: TopRankCardProps) {
  return (
    <div
      className={cn(
        'bg-white rounded-[8px] overflow-hidden shadow border border-gray-4 grid grid-cols-[81.78px_1fr] desktop-tablet:grid-cols-[106px_1fr]',
        className
      )}
    >
      <div
        className={cn('w-[81.78px] rounded-r-[8px] overflow-hidden', {
          'desktop-tablet:w-[106px]': mode === 'swiper',
          'desktop:w-[106px]': mode === 'rank',
        })}
      >
        <div className='relative pt-[140.625%] w-full h-fll bg-gray-3'>
          {bookDetail.book.coverResizeImgPath ||
          bookDetail.book.coverImgPath ? (
            <Image
              src={
                bookDetail.book.coverResizeImgPath ||
                bookDetail.book.coverImgPath
              }
              alt={bookDetail.book.title}
              layout='fill'
              sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
              quality={25}
            />
          ) : null}
        </div>
      </div>
      <div
        className={cn('w-full p-[10px] flex flex-col justify-between', {
          'desktop-tablet:p-[15px]': mode === 'swiper',
          'desktop:p-[15px]': mode === 'rank',
        })}
      >
        <div>
          <div className='text-secondary font-mitr text-base mobile:text-[14px] font-medium line-clamp-1 break-all'>
            {bookDetail.book.title}
          </div>
          <div className='flex space-x-[4px] text-sm mobile:text-[12px] font-light mt-[5px]'>
            <span className='text-secondary-1 whitespace-nowrap'>
              <PenIcon className='w-[18px] h-[18px]' />
            </span>
            <div onClick={e => e.stopPropagation()}>
              <Link
                href={`/profile/${slug}?tab=penName&text=${bookDetail.book.penName.firstPenName}`}
                legacyBehavior
                prefetch={false}
              >
                <a className='hover:underline line-clamp-1 break-all'>
                  {bookDetail.book.penName.firstPenName}
                </a>
              </Link>
            </div>
          </div>
          <div
            className={cn(
              'flex flex-row items-center space-x-[3px] mt-[10px] mobile:mt-[5px]',
              {
                'desktop-tablet:space-x-[10px]': mode === 'swiper',
                'desktop:space-x-[10px]': mode === 'rank',
              }
            )}
          >
            <div
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <Link
                href={{
                  pathname: '/category',
                  query: {
                    id: bookDetail.book.category.id,
                    ...(bookDetail.book.bookType !== 'EBOOK'
                      ? { bookType: bookDetail.book.bookType }
                      : { isEbook: true }),
                  },
                }}
                legacyBehavior
                prefetch={false}
              >
                <a className='text-primary text-[12px] line-clamp-1 underline font-light'>
                  {bookDetail.book.category.name}
                </a>
              </Link>
            </div>
            <div className='w-[1px] h-[11px] bg-gray-3' />
            <EyeIcon className='text-secondary' width='18' height='18' />
            <span className='text-[12px] text-secondary-1 w-[40px] font-light'>
              {abbreviateNumber(bookDetail.totalViewCount)}
            </span>
            <button
              type='button'
              disabled={isLoadingToggleBookLike}
              onClick={(event: React.MouseEvent) => {
                if (handleLikeBook) {
                  event.stopPropagation()
                  handleLikeBook(bookDetail.book.id)
                }
              }}
            >
              {bookDetail.book.isLiked ? (
                <HeartSolidIcon className='w-[18px] h-[18px] text-red' />
              ) : (
                <HeartIcon className='w-[18px] h-[18px] text-secondary' />
              )}
            </button>
            <span
              className={cn('text-[12px] w-[40px] font-light', {
                'text-secondary': bookDetail.book.isLiked,
                'text-secondary-1': !bookDetail.book.isLiked,
              })}
            >
              {abbreviateNumber(bookDetail.totalLikeCount)}
            </span>
          </div>
        </div>

        <div className='flex items-center space-x-[5px] text-secondary-1 font-light text-sm tablet-mobile:text-[12px] mr-[10px] cursor-pointer select-none'>
          {bookDetail.previousRank === null ? (
            <>
              <TopChartIcon width='18' height='18' className='text-primary' />
              <span className='truncate'>
                <span className='font-bold text-secondary'>ติด Top Chart</span>{' '}
                สัปดาห์แรก
              </span>
            </>
          ) : bookDetail.rankChange === 0 ? (
            <>
              <Minus2Icon width='18' height='20' />
              <span className='text-secondary'>อันดับคงที่</span>
            </>
          ) : bookDetail.rankChange > 0 ? (
            <>
              <ArrowUpIcon
                width='18'
                height='18'
                className='text-primary rotate-180'
              />
              <p className='truncate'>
                ลงมา{' '}
                <span className='font-bold text-secondary'>
                  {bookDetail?.rankChange} อันดับ{' '}
                </span>
                จากสัปดาห์ที่แล้ว
              </p>
            </>
          ) : (
            bookDetail.rankChange < 0 && (
              <>
                <ArrowUpIcon width='18' height='18' className='text-primary' />
                <p className='truncate'>
                  ขึ้นมา{' '}
                  <span className='font-bold text-secondary'>
                    {Math.abs(bookDetail?.rankChange)} อันดับ{' '}
                  </span>
                  จากสัปดาห์ที่แล้ว
                </p>
              </>
            )
          )}
        </div>
      </div>
    </div>
  )
}
