import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { DateTime } from 'luxon'
import cloneDeep from 'lodash.clonedeep'
import { useRouter } from 'next/router'

import { useAuthentication } from '@hooks/useAuthentication'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { LikeSuccessStatusEnum } from '@models/book/LikeSuccessStatusEnum'
import { FunctionComponentType } from '@interfaces/commons/FunctionComponent'
import { BookEnum } from '@interfaces/BookEnum'
import { TopChartEnum } from '@interfaces/TopChartEnum'
import { BookTopChartEnum } from '@interfaces/BookTopChartEnum'
import { BookTopChartsResponse } from '@models/story/BookTopChartsResponse'
import { useCategories } from '@hooks/useCategories'
import { BookTopChartsParam, useBookAction } from './useBookAction'

interface BookTopChartProps extends FunctionComponentType {
  bookType?: BookEnum
  categoryId?: string
  startDate?: string
  endDate?: string
  bookTopChartType: BookTopChartEnum
  limit: number
  serverDate: string
}

const initialQuery: BookTopChartsParam = {
  categoryId: undefined,
  limit: 10,
  bookType: BookEnum.NOVEL,
  topChartType: TopChartEnum.WEEK,
  bookTopChartType: BookTopChartEnum.VIEW,
  startDate: DateTime.local().minus({ days: 7 }).startOf('week'),
  endDate: DateTime.local().minus({ days: 7 }).endOf('week'),
}

const TopViewContext = createContext<{
  data?: InfiniteData<BookTopChartsResponse>
  query: BookTopChartsParam
  limit: number
  lastWeek: DateTime
  isLoading: boolean
  isLoadingToggleBookLike: boolean
  handleCategoryChange: (_: string) => void
  handleBookTypeChange: (_: BookEnum) => void
  nextWeek: () => void
  prevWeek: () => void
  handleFetchNextPage: () => void
  handleLikeBook: (_: number) => Promise<void>
}>({
  data: undefined,
  query: initialQuery,
  limit: 10,
  lastWeek: DateTime.local().minus({ days: 7 }),
  isLoading: true,
  isLoadingToggleBookLike: true,
  handleCategoryChange: (_: string) => {},
  handleBookTypeChange: (_: BookEnum) => {},
  nextWeek: () => {},
  prevWeek: () => {},
  handleFetchNextPage: () => {},
  handleLikeBook: async (_: number) => {},
})

export const BookTopChartProvider = React.memo(
  ({
    children,
    categoryId,
    bookType = BookEnum.NOVEL,
    bookTopChartType,
    startDate,
    endDate,
    limit,
    serverDate,
  }: BookTopChartProps) => {
    const { isAuthenticated, canFetchApi } = useAuthentication()
    const lastWeek = DateTime.fromISO(serverDate).minus({
      days: 7,
    })
    const bookClient = useBookAction()
    const queryClient = useQueryClient()
    const { novelCategories, mangaCategories } = useCategories()
    const [query, setQuery] = useState({
      ...initialQuery,
      limit,
      bookType,
      categoryId,
      bookTopChartType,
      startDate: startDate
        ? DateTime.fromISO(startDate)
        : lastWeek.startOf('week'),
      endDate: endDate ? DateTime.fromISO(endDate) : lastWeek.endOf('week'),
    })
    const loginModal = useModal({ modal: 'login' })
    const router = useRouter()

    const {
      fetchNextPage,
      data: bookTopChart,
      isLoading,
      isFetching,
      isFetchingNextPage,
    } = useInfiniteQuery(
      ['top-chart', query],
      async ({ pageParam = 1 }) => {
        const res = await bookClient.getBookTopCharts({
          ...query,
          page: pageParam,
        })

        return res
      },
      {
        enabled: canFetchApi,
        getNextPageParam: lastPage => {
          if (lastPage && lastPage.data.length && lastPage.page * limit < 200)
            return lastPage.page + 1

          return undefined
        },
      }
    )

    function handleFetchNextPage() {
      if (!isFetchingNextPage) fetchNextPage()
    }

    const handleCategoryChange = useCallback(
      (value: string) => {
        setQuery(prev => ({
          ...prev,
          categoryId: value,
        }))
        if (['/top-view', '/top-purchase'].includes(router.pathname)) {
          router.replace({
            pathname: router.pathname,
            query: value !== '' ? { categoryId: value } : undefined,
          })
        }
      },
      [router]
    )

    const handleBookTypeChange = useCallback(
      (value: BookEnum) => {
        const categories =
          value === BookEnum.NOVEL ? novelCategories : mangaCategories

        setQuery(prev => ({
          ...prev,
          categoryId: categories.find(row => row.value === prev.categoryId)
            ? prev.categoryId
            : '',
          bookType: value,
        }))
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const nextWeek = useCallback(() => {
      setQuery(prev => ({
        ...prev,
        startDate: prev.startDate.plus({ days: 7 }),
        endDate: prev.endDate.plus({ days: 7 }),
      }))
    }, [])

    const prevWeek = useCallback(() => {
      setQuery(prev => ({
        ...prev,
        startDate: prev.startDate.minus({ days: 7 }),
        endDate: prev.endDate.minus({ days: 7 }),
      }))
    }, [])

    const { mutate: toggleBookLike, isLoading: isLoadingToggleBookLike } =
      useMutation((bookId: number) => bookClient.toggleBookLike(bookId), {
        onSuccess: (type, bookId) => {
          queryClient.setQueryData<
            InfiniteData<BookTopChartsResponse | undefined> | undefined
          >(['top-chart', query], oldData => {
            if (oldData) {
              const tmp = cloneDeep(oldData)

              return {
                ...tmp,
                pages: tmp.pages.map(page => {
                  if (page) {
                    return {
                      ...page,
                      data: page?.data.map(row => {
                        return {
                          ...row,
                          book: {
                            ...row.book,
                            isLiked:
                              row.book.id === bookId
                                ? type === LikeSuccessStatusEnum.CREATE
                                : row.book.isLiked,
                            likeCount:
                              row.book.id === bookId
                                ? type === LikeSuccessStatusEnum.CREATE
                                  ? row.book.likeCount + 1
                                  : Math.max(0, row.book.likeCount - 1)
                                : row.book.likeCount,
                          },
                        }
                      }),
                    }
                  }

                  return page
                }),
              }
            }

            return oldData
          })
        },
      })

    async function handleLikeBook(bookId: number) {
      if (isAuthenticated) {
        await toggleBookLike(bookId)
      } else {
        loginModal.show()
      }
    }

    useEffect(() => {
      setQuery(prev => ({
        ...prev,
        categoryId,
      }))
    }, [categoryId])

    const value = {
      data: bookTopChart,
      query,
      limit,
      lastWeek,
      isLoading: isLoading || isFetching,
      isLoadingToggleBookLike,
      handleCategoryChange,
      handleBookTypeChange,
      nextWeek,
      prevWeek,
      handleLikeBook,
      handleFetchNextPage,
    }

    return (
      <TopViewContext.Provider value={value}>
        {children}
      </TopViewContext.Provider>
    )
  }
)

export function useBookTopCharts() {
  return useContext(TopViewContext)
}
